input {
    font-size: 14pt;
}

.euiFieldText {
    font-size: 14pt;
}

.euiTextArea {
    font-size: 14pt;
}

@media only screen and (hover: none) and (pointer: coarse){

    input {
        font-size: 16pt;
    }
    
    .euiFieldText {
        font-size: 16pt;
    }
    
    .euiTextArea {
        font-size: 16pt;
    }

}