.button-group {
    display: flex; /* Use flexbox to horizontally align the buttons */
    gap: 10px; /* Adjust the gap between buttons as needed */
    flex-wrap: wrap;
    flex-direction: row;
    align-content: space-between;
    
}

.button-group button {
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

  